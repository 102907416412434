const devMode = process.env.NODE_ENV !== 'production';
document.body.classList.remove('no-js');

const image = new Image(1, 1);

const getClassToAdd = () => (image.height === 1 && !devMode ? 'webp' : 'no-webp');

const addWebpClass = () => {
    const classToAdd = getClassToAdd();
    document.body.classList.add(classToAdd);
};

const handleWebpSupport = () => {
    image.onload = addWebpClass;
    image.onerror = addWebpClass;
};

image.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';

handleWebpSupport();

